import { template as template_0f2e64da296941b9940793d0736b4ede } from "@ember/template-compiler";
import { i18n } from "discourse-i18n";
import SortableColumn from "./sortable-column";
const PostersCell = template_0f2e64da296941b9940793d0736b4ede(`
  <SortableColumn
    @order="posters"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="posters"
    @screenreaderOnly={{true}}
    aria-label={{i18n "category.sort_options.posters"}}
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PostersCell;
