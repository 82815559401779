import { template as template_67675b1a186f47ab8d4526ce3cebc5ad } from "@ember/template-compiler";
const FKText = template_67675b1a186f47ab8d4526ce3cebc5ad(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
