import { template as template_e67e52af1f034b5daa2974d15573f875 } from "@ember/template-compiler";
const WelcomeHeader = template_e67e52af1f034b5daa2974d15573f875(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
