import { template as template_f5e5bac6ce754aa4ab2d96bec6b714c6 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_f5e5bac6ce754aa4ab2d96bec6b714c6(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
