import { template as template_f0e01bf2d00a4f1694930a1b9f14b3d5 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_f0e01bf2d00a4f1694930a1b9f14b3d5(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
